import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'

export const InvoicesListSkeleton = ({ items = 3 }) => {
  return (
    <div>
      {[...Array(items)].map((_, index) => (
        <Skeleton key={index} height={80} className="mb-3" />
      ))}
    </div>
  )
}
