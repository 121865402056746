import { useContext } from 'react'

import classNames from 'classnames'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { useAppContext } from '@/domain/App/context'
import { InvoicesListItemContext } from '@/domain/Resources/Invoices/ListItem'
import { InvoicesReceiptModal } from '@/domain/Resources/Invoices/ReceiptModal'
import { SendProofOfPaymentModal } from '@/domain/Resources/Invoices/SendProofOfPaymentModal'
import OrdersChangeCardModal from '@/domain/Resources/Orders/ChangeCardModal'
import { OrdersChangeDueDateDayModal } from '@/domain/Resources/Orders/ChangeDueDateDayModal'

const InvoicesListItemActionsRoot = ({ children }) => (
  <div className="d-flex gap-2">{children}</div>
)

const InvoicesListItemActionsPayButton = ({ uid }) => {
  const { t } = useTranslation()

  return (
    <Link href={`/payments/${uid}/checkout`} target="_blank">
      <button className="btn btn-primary btn-sm">{t('pay')}</button>
    </Link>
  )
}

const InvoicesListItemActionsShowReceiptButton = ({ id }) => {
  const { t } = useTranslation()
  const { showModal } = useAppContext()

  return (
    <button
      className="btn btn-icon btn-sm btn-light btn-active-light-primary"
      title={t('showReceipt')}
      onClick={() => showModal({ component: InvoicesReceiptModal, id })}
    >
      <i className="fas fa-file-invoice-dollar"></i>
    </button>
  )
}

const InvoicesListItemActionsChangeCreditCardButton = ({ orderId, onHide }) => {
  const { t } = useTranslation()
  const { showModal } = useAppContext()

  return (
    <button
      className="btn btn-icon btn-sm btn-light btn-active-light-primary"
      title={t('changeCreditCard')}
      onClick={() =>
        showModal({
          component: OrdersChangeCardModal,
          orderId,
          onHide
        })
      }
    >
      <i className="fas fa-credit-card-front"></i>
    </button>
  )
}

const InvoicesListItemActionsChangeDueDateDayButton = ({
  orderId,
  installment,
  onHide
}) => {
  const { t } = useTranslation()
  const { showModal } = useAppContext()

  return (
    <button
      className="btn btn-icon btn-sm btn-light btn-active-light-primary"
      title={t('changeDueDateDay')}
      onClick={() =>
        showModal({
          component: OrdersChangeDueDateDayModal,
          id: orderId,
          installment,
          onHide
        })
      }
    >
      <i className="fas fa-calendar-pen"></i>
    </button>
  )
}

const InvoicesListItemActionsSendProofOfPaymentButton = ({ id, onHide }) => {
  const { t } = useTranslation()
  const { showModal } = useAppContext()

  const invoice = useContext(InvoicesListItemContext)
  const hasProofOfPaymentRequest = !!invoice?.proof_of_payment_request_id
  const proofOfPaymentRequestStatus = invoice?.proof_of_payment_request?.status
  const proofOfPaymentRequestReason = invoice?.proof_of_payment_request?.reason

  const buttonClasses = classNames('btn btn-icon btn-sm', {
    'btn-light-warning border border-warning':
      proofOfPaymentRequestStatus === 'pending',
    'btn-light-danger border border-danger':
      proofOfPaymentRequestStatus === 'disapproved',
    'btn-light btn-active-light-primary': !hasProofOfPaymentRequest
  })

  const iconClasses = classNames({
    'far fa-file-circle-exclamation': proofOfPaymentRequestStatus === 'pending',
    'far fa-file-circle-xmark': proofOfPaymentRequestStatus === 'disapproved',
    'fas fa-file-import': !hasProofOfPaymentRequest
  })

  let proofOfPaymentTooltip = t('sendProofOfPayment')
  if (hasProofOfPaymentRequest) {
    if (proofOfPaymentRequestStatus === 'pending') {
      proofOfPaymentTooltip = t('proofOfPaymentUnderAnalysis')
    } else if (proofOfPaymentRequestStatus === 'disapproved') {
      proofOfPaymentTooltip = t('proofOfPaymentDisapproved', {
        reason: proofOfPaymentRequestReason
      })
    }
  }

  return (
    <button
      className={buttonClasses}
      title={proofOfPaymentTooltip}
      onClick={() =>
        showModal({ component: SendProofOfPaymentModal, id, onHide })
      }
    >
      <i className={iconClasses}></i>
    </button>
  )
}

export const InvoicesListItemActions = {
  Root: InvoicesListItemActionsRoot,
  PayButton: InvoicesListItemActionsPayButton,
  ShowReceiptButton: InvoicesListItemActionsShowReceiptButton,
  ChangeCreditCardButton: InvoicesListItemActionsChangeCreditCardButton,
  ChangeDueDateDayButton: InvoicesListItemActionsChangeDueDateDayButton,
  SendProofOfPaymentButton: InvoicesListItemActionsSendProofOfPaymentButton
}
