import { createContext } from 'react'

import _ from 'lodash'
import { DateTime } from 'luxon'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { useResourceRecordsContext } from '@tootz/react-admin'

import OrdersChangeCardModal from '../../Orders/ChangeCardModal'

import { useAppContext } from '@/domain/App/context'

import { InvoicesListItemActions } from './Actions'
import styles from './styles.module.scss'

export const InvoicesListItemContext = createContext(undefined)

const InvoicesListItemRoot = ({ children, ...invoice }) => {
  const {
    description,
    status,
    cents,
    currency,
    gateway_service: gatewayService,
    payment_order: paymentOrder,
    payment_method: paymentMethod,
    due_date: dueDate,
    charged_at: chargedAt,
    proof_of_payment_request: proofOfPaymentRequest
  } = invoice
  const { proof_of_payment: proofOfPayment } = proofOfPaymentRequest || {}
  const { showModal } = useAppContext()
  const { t } = useTranslation()
  const {
    refetch,
    config: { overdueStatuses, prePaymentStatuses }
  } = useResourceRecordsContext()
  const isOverdue =
    _.includes(overdueStatuses, status) ||
    (_.includes(prePaymentStatuses, status) &&
      DateTime.fromISO(dueDate).diffNow(['hours']).hours < 0)

  const isPaid = status === 'paid'

  const date = isPaid
    ? DateTime.fromISO(chargedAt).toLocaleString(DateTime.DATETIME_MED)
    : DateTime.fromFormat(dueDate, 'yyyy-MM-dd').toLocaleString(
        DateTime.DATE_MED
      )

  return (
    <InvoicesListItemContext.Provider value={invoice}>
      <div
        className={`${
          styles.invoicesListItem
        } position-relative bg-gray-100 rounded d-flex flex-column flex-md-row align-items-md-center ps-6 py-4 pe-6 gap-4 ${
          isOverdue ? 'border border-danger' : ''
        }`}
      >
        {isOverdue && (
          <div className="position-absolute start-0 top-50 translate-middle w-6px h-75 rounded-2 bg-danger"></div>
        )}
        <div className="flex-fill">
          <div className="mb-1 text-gray-900 fw-bold">{description}</div>
          <div className="d-flex flex-column flex-lg-row gap-lg-2 align-items-lg-center">
            <div class="fs-7 text-muted fw-bold">
              {t(isPaid ? 'paidAt' : 'dueAt', { date })}
            </div>
            <div className="d-flex align-items-center gap-2">
              {status === 'paid_with_proof' ? (
                <>
                  <div className="w-4px h-4px bg-gray-500 rounded-pill d-none d-lg-block"></div>
                  <div class="fs-7 text-muted fw-bold">
                    {t('proofOfPayment')}
                    <Link
                      href={proofOfPayment?.file?.url}
                      target="_blank"
                      className={`ms-2 ${styles.changeCreditCardBtn}`}
                    >
                      <i className="fas fa-up-right-from-square text-primary"></i>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  {gatewayService == 'stripe' && (
                    <>
                      <div className="w-4px h-4px bg-gray-500 rounded-pill d-none d-lg-block"></div>
                      <div class="fs-7 text-muted fw-bold">
                        {t('creditCardLine', {
                          last4: _.get(paymentMethod, 'meta.last4', '****'),
                          brand: _.get(paymentMethod, 'meta.brand', 'Card')
                        })}
                      </div>
                    </>
                  )}
                  {!isPaid && gatewayService != 'brazilpays' && (
                    <button
                      className={`${styles.changeCreditCardBtn} change-credit-card-btn btn btn-icon btn-color-primary w-16px h-16px p-0`}
                      title={t('changeCreditCard')}
                      onClick={() =>
                        showModal({
                          component: OrdersChangeCardModal,
                          orderId: paymentOrder?.id,
                          onHide: refetch
                        })
                      }
                    >
                      <i className="fad fa-arrows-rotate"></i>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div class="fw-bolder fs-3">
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency
              }).format(cents / 100)}
            </div>
          </div>
        </div>
        {children}
      </div>
    </InvoicesListItemContext.Provider>
  )
}

export const InvoicesListItem = {
  Root: InvoicesListItemRoot,
  Actions: InvoicesListItemActions.Root,
  PayButton: InvoicesListItemActions.PayButton,
  ShowReceiptButton: InvoicesListItemActions.ShowReceiptButton,
  ChangeCreditCardButton: InvoicesListItemActions.ChangeCreditCardButton,
  ChangeDueDateDayButton: InvoicesListItemActions.ChangeDueDateDayButton,
  SendProofOfPaymentButton: InvoicesListItemActions.SendProofOfPaymentButton
}
