import { withResourceRecords } from '@tootz/react-admin'

import { getConfig } from '../config'

import { InvoicesListFilters } from './Filters'
import { InvoicesListItems } from './Items'
import { InvoicesListPagination } from './Pagination'
import { InvoicesListTitle } from './Title'

const InvoicesListRoot = withResourceRecords(({ children }) => {
  return <div>{children}</div>
}, getConfig)

export const InvoicesList = {
  Root: InvoicesListRoot,
  Title: InvoicesListTitle,
  Items: InvoicesListItems,
  Filters: InvoicesListFilters,
  Pagination: InvoicesListPagination
}
