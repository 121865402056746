import _ from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import {
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'
import { FormGroup, FileInput } from '@tootz/react-admin/form'

import { getConfig } from '../config'

export const SendProofOfPaymentModal = withResourceRecord(
  ({ show, onHide }) => {
    const { t } = useTranslation()
    const { record: invoice, Form, isSubmitting } = useResourceRecordContext()

    const fileUrl = _.get(
      invoice,
      'proof_of_payment_request.proof_of_payment.file.url'
    )
    const hasProofOfPaymentRequest = !!invoice?.proof_of_payment_request_id
    const proofOfPaymentRequestStatus =
      invoice?.proof_of_payment_request?.status
    const proofOfPaymentRequestReason =
      invoice?.proof_of_payment_request?.reason

    return (
      <Modal centered show={show} onHide={onHide} contentClassName="rounded-4">
        <Modal.Header onHide={onHide}>
          <Modal.Title>{t('sendProofOfPayment')}</Modal.Title>
          <Modal.SubTitle>{t('sendProofOfPaymentDisclaimer')}</Modal.SubTitle>
        </Modal.Header>
        <Form
          onSave={onHide}
          customAction={{ name: 'send_proof_of_payment', method: 'put' }}
        >
          <Modal.Body className="pt-0">
            {hasProofOfPaymentRequest &&
              (proofOfPaymentRequestStatus === 'pending' ? (
                <div className="alert alert-dismissible bg-light-warning border-warning border border-dashed d-flex flex-column flex-sm-row p-5 mb-6">
                  <i className="fad fa-warning fs-2hx text-warning me-5 mb-5 mb-sm-0"></i>
                  <div class="d-flex flex-column pe-0 pe-sm-10">
                    <h5 class="fw-semibold">
                      {t('proofOfPaymentUnderAnalysis')}
                    </h5>

                    <span>
                      {`${t('proofOfPaymentUnderAnalysisText')} `}
                      <Link href={fileUrl} target="_blank">
                        {_.lowerCase(t('clickingHere'))}
                      </Link>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="alert alert-dismissible bg-light-danger border-danger border border-dashed d-flex flex-column flex-sm-row p-5 mb-6">
                  <i className="fad fa-times-circle fs-2hx text-danger me-5 mb-5 mb-sm-0"></i>
                  <div class="d-flex flex-column pe-0 pe-sm-10">
                    <h5 class="fw-semibold">
                      {t('proofOfPaymentDisapproved')}
                    </h5>

                    {proofOfPaymentRequestStatus === 'disapproved' && (
                      <span>
                        {`${t('proofOfPaymentDisapprovedReason', {
                          reason: proofOfPaymentRequestReason
                        })} `}
                      </span>
                    )}
                    <span>
                      {`${t('proofOfPaymentUnderAnalysisText')} `}
                      <Link href={fileUrl} target="_blank">
                        {_.lowerCase(t('clickingHere'))}
                      </Link>
                    </span>
                  </div>
                </div>
              ))}
            <FormGroup
              name="proof_of_payment_id"
              control={FileInput}
              label={t('selectFileInFormats')}
              mediaApiScope="client"
              solid
              required
              vertical
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? t('auth.sending') : t('auth.send')}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  },
  getConfig
)
