import Skeleton from 'react-loading-skeleton'

export const InvoicesReceiptModalSkeleton = () => {
  return (
    <div className="rounded-4">
      <div className="modal-content rounded-4">
        <div>
          <div className="flex-wrap text-center border-0 pb-3 modal-header">
            <div className="d-flex flex-column m-2 m-xl-4 w-100 pt-3">
              <div className="position-absolute top-0 start-50 translate-middle bg-white rounded-pill p-4 shadow-sm d-print-none">
                <Skeleton circle width={48} height={48} />
              </div>
              <span className="h2 mb-2 d-block ">
                <Skeleton width={200} height={24} />
              </span>
              <div className="text-muted fw-bold fs-6">
                <Skeleton width={300} />
                <Skeleton width={100} />
              </div>
              <div className="d-flex flex-column align-items-center mt-3">
                <Skeleton width={80} height={18} />
                <Skeleton width={140} height={32} />
              </div>
            </div>
          </div>
          <div className="modal-body px-10 px-lg-15 pb-10  scroll-y--disabled  pt-8 border-top border-gray-400 modal-body">
            <div>
              {[...Array(4)].map((_, index) => (
                <>
                  {index > 0 && (
                    <div className="separator separator-dashed my-3"></div>
                  )}
                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-semibold fs-7 me-2">
                      <Skeleton width={80} height={14} />
                    </div>
                    <div className="d-flex align-items-senter">
                      <Skeleton width={160} height={14} />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="border-0 justify-content-center pt-0 px-10 px-lg-15 pb-lg-10  modal-footer">
          <Skeleton width={100} height={40} />
        </div>
      </div>
    </div>
  )
}
