import { useEffect } from 'react'

import useTranslation from 'next-translate/useTranslation'
import { useFormContext } from 'react-hook-form'

import { BtnGroupRadio, FormSwitch } from '@tootz/react-admin/form'

export const OrdersChangeDueDateDayModalFields = () => {
  const { t } = useTranslation()
  const { reset } = useFormContext()

  useEffect(() => {
    return () => reset({ target_day: '', change_all_installments: false })
  }, [])

  return (
    <div>
      <BtnGroupRadio
        name="target_day"
        options={[
          { label: `${t('day')} 05`, value: '5' },
          { label: `${t('day')} 20`, value: '20' }
        ]}
        asCards
      />
      <label className="d-flex gap-4 align-items-center mt-8">
        <FormSwitch
          name="change_all_installments"
          options={{
            onColor: '#ffdede',
            onHandleColor: '#ff5959'
          }}
        />
        <span>{t('changeAllInstallments')}</span>
      </label>
    </div>
  )
}
