export const Toolbar = ({ className, title, children }) => {
  return (
    <div
      className={`border-bottom border-1 min-h-50px min-h-md-75px mb-6 ${className}`}
    >
      <div className="container">
        <div className="d-flex align-items-center pt-2 pb-4 mx-2 mx-lg-6">
          {title && <span className="fs-1 fw-bolder">{title}</span>}
          {children}
        </div>
      </div>
    </div>
  )
}
