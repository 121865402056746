import { useRef, useState } from 'react'

import { DateTime } from 'luxon'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { useReactToPrint } from 'react-to-print'

import {
  useResourceRecordContext,
  useSession,
  withResourceRecord
} from '@tootz/react-admin'
import { LoadWrapper, Modal } from '@tootz/react-admin/components'

import { getConfig } from '../config'

import { InvoicesReceiptModalSkeleton } from './Skeleton'

export const InvoicesReceiptModal = withResourceRecord(({ id, onHide }) => {
  const { t } = useTranslation()
  const { record: invoice, isLoading } = useResourceRecordContext()
  const { data: session } = useSession()
  const contentToPrint = useRef(null)
  const [isPrinting, setIsPrinting] = useState(false)
  const handlePrint = useReactToPrint({
    documentTitle: t('receipt', { description: invoice?.description }),
    onAfterPrint: () => setIsPrinting(false),
    removeAfterPrint: true
  })
  const fieldsToShow = [
    {
      label: t('invoice.uid'),
      field: 'uid',
      condition: invoice?.status === 'paid'
    },
    {
      label: t('invoice.chargedAt'),
      field: 'charged_at',
      formatter: v => DateTime.fromISO(v).toLocaleString(DateTime.DATETIME_MED),
      condition: invoice?.status === 'paid'
    },
    {
      label: t('invoice.chargedAt'),
      field: 'proof_of_payment_request.created_at',
      formatter: v => DateTime.fromISO(v).toLocaleString(DateTime.DATETIME_MED),
      condition: invoice?.status === 'paid_with_proof'
    },
    {
      label: t('invoice.paymentMethod'),
      field: 'payment_method.meta',
      formatter: v => t('creditCardLine', { last4: v?.last4, brand: v?.brand }),
      condition:
        invoice?.status === 'paid' && invoice?.provider !== 'brazilpays'
    },
    {
      label: t('invoice.paymentMethod'),
      field: 'payment_method.meta',
      formatter: v => (
        <>
          {t('proofOfPayment')}
          <Link
            href={
              invoice?.proof_of_payment_request?.proof_of_payment?.file?.url
            }
            target="_blank"
            className="ms-2 d-print-none"
          >
            <i className="fas fa-up-right-from-square text-primary"></i>
          </Link>
        </>
      ),
      condition:
        invoice?.status === 'paid_with_proof' &&
        invoice?.provider !== 'brazilpays'
    },
    {
      label: t('invoice.payer'),
      // payer name doesnt exist in the invoice, getting from session
      field: 'id',
      formatter: v => session?.user?.roles[0]?.client?.name
    }
  ]

  return (
    <Modal show={!!id} contentClassName="rounded-4" centered>
      <LoadWrapper
        isLoading={isLoading}
        skeleton={<InvoicesReceiptModalSkeleton />}
      >
        <div ref={contentToPrint}>
          {/* Custom margins to print page */}
          <style>{`@page { margin: 50px 200px 0 200px !important; }`}</style>

          <Modal.Header
            onHide={onHide}
            className="pb-3"
            hideButtonClassName="d-print-none"
          >
            <div className="position-absolute top-0 start-50 translate-middle bg-white rounded-pill p-4 shadow-sm d-print-none">
              <i className="fas fa-check-circle text-success fs-3x"></i>
            </div>
            <div className="d-none d-print-block text-center my-8">
              <img src="/logo-dark.png" alt="logo" height={40} />
            </div>
            <Modal.Title>{t('paymentReceived')}!</Modal.Title>
            <Modal.SubTitle>
              <span className="text-gray-700">{invoice?.description}</span>
            </Modal.SubTitle>
            <div className="d-flex flex-column align-items-center mt-3">
              <span className="fs-7 text-muted">{t('paidValue')}</span>
              {!!invoice && (
                <span className="fs-1 fw-bolder">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: invoice?.currency || 'BRL'
                  }).format(invoice?.cents / 100)}
                </span>
              )}
            </div>
          </Modal.Header>
          <Modal.Body className="pt-8 border-top border-gray-400">
            <div>
              {fieldsToShow.map(
                ({ label, field, formatter, condition }, index, arr) =>
                  (typeof condition === 'undefined' || condition) && (
                    <>
                      <div
                        className="d-flex flex-stack text-nowrap"
                        key={field}
                      >
                        <div className="text-gray-700 fw-semibold fs-7 me-2">
                          {label}
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="text-gray-700 fw-bolder fs-7">
                            {_.isFunction(formatter)
                              ? formatter(_.get(invoice, field))
                              : _.get(invoice, field)}
                          </span>
                        </div>
                      </div>

                      {index < arr.length - 1 && (
                        <div className="separator separator-dashed my-3"></div>
                      )}
                    </>
                  )
              )}
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              setIsPrinting(true)
              handlePrint(null, () => contentToPrint?.current)
            }}
            disabled={isPrinting}
          >
            <i className="fad fa-print me-1"></i>
            {t(isPrinting ? 'loading' : 'print')}
          </button>
        </Modal.Footer>
      </LoadWrapper>
    </Modal>
  )
}, getConfig)
