import _ from 'lodash'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useResourceRecordsContext } from '@tootz/react-admin'

import { PAID_INVOICES_CONFIG, NOT_PAID_INVOICES_CONFIG } from '../config'

export const InvoicesListFilters = () => {
  const { t } = useTranslation()
  const { FiltersForm, setFilter, sort, setParamsAttrs, params } =
    useResourceRecordsContext()
  const router = useRouter()

  const handleFilter = situationSlug => {
    setParamsAttrs('filter', {}, 'pagination.page', 1)
    setFilter(_.find(situations, { slug: situationSlug }).filter)
    sort(_.find(situations, { slug: situationSlug }).sort)
    router.push(`/financial/${situationSlug}`, undefined, { shallow: true })
  }

  const situations = [
    {
      slug: 'pending',
      label: t('pendings'),
      filter: NOT_PAID_INVOICES_CONFIG.filter,
      sort: NOT_PAID_INVOICES_CONFIG.sort
    },
    {
      slug: 'paid',
      label: t('paids'),
      filter: PAID_INVOICES_CONFIG.filter,
      sort: PAID_INVOICES_CONFIG.sort
    }
  ]

  const current = _.has(params?.filter, 'status_not_in') ? 'pending' : 'paid'

  return (
    <FiltersForm>
      <div className="d-flex gap-4">
        {situations.map(situation => (
          <button
            key={situation.slug}
            type="button"
            onClick={() => handleFilter(situation.slug)}
            className={`fs-4 border-0 bg-transparent ${
              current !== situation.slug ? 'text-gray-500 fw-bold' : 'fw-bolder'
            }`}
          >
            {situation.label}
          </button>
        ))}
      </div>
    </FiltersForm>
  )
}
