import useTranslation from 'next-translate/useTranslation'

export const NOT_PAID_INVOICES_CONFIG = {
  filter: { status_not_in: ['paid', 'paid_with_proof'] },
  sort: { field: 'due_date', order: 'asc' }
}

export const PAID_INVOICES_CONFIG = {
  filter: { status_in: ['paid', 'paid_with_proof'] },
  sort: { field: 'due_date', order: 'desc' }
}

export const getConfig = (props = {}) => {
  const {} = props

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation()

  const statuses = [
    { slug: 'draft', name: t('titles.pending'), variant: 'warning' },
    { slug: 'pending', name: t('titles.pending'), variant: 'warning' },
    { slug: 'paid', name: t('titles.paid'), variant: 'success' },
    { slug: 'canceled', name: 'Cancelado', variant: 'danger' },
    { slug: 'refunded', name: 'Reembolsado', variant: 'dark' },
    { slug: 'expired', name: 'Expirado', variant: 'danger' },
    { slug: 'authorized', name: 'Autoizado', variant: 'success' },
    { slug: 'in_protest', name: 'Em Protesto', variant: 'warning' },
    { slug: 'chargeback', name: 'Estornado', variant: 'danger' },
    { slug: 'overdue', name: 'Inadimplente', variant: 'danger' },
    { slug: 'failed', name: 'Falhou', variant: 'danger' }
  ]

  const overdueStatuses = [
    'overdue',
    'in_protest',
    'expired',
    'canceled',
    'failed'
  ]

  const prePaymentStatuses = ['draft', 'pending']

  return {
    apiBasePath: '/client/invoices',
    statuses,
    overdueStatuses,
    prePaymentStatuses
  }
}
