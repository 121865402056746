import useTranslation from 'next-translate/useTranslation'
import toastr from 'toastr'

import {
  useResourceRecordContext,
  withResourceRecord
} from '@tootz/react-admin'
import { Modal } from '@tootz/react-admin/components'

import useConfig from '../config'

import { OrdersChangeDueDateDayModalFields } from './Fields'

export const OrdersChangeDueDateDayModal = withResourceRecord(
  ({ id, installment, onHide }) => {
    const { t } = useTranslation()
    const { Form, isSubmitting } = useResourceRecordContext()

    const handleChange = () => {
      toastr.success(t('dueDateDayChanged'), t('done'))
      onHide()
    }

    const handleBeforeSave = data => {
      if (!data?.change_all_installments) {
        data.installment = installment
      }

      return data
    }

    return (
      <Modal show={!!id} onHide={onHide} centered>
        <Modal.Header onHide={onHide}>
          <Modal.Icon className={`fa-duotone fa-credit-card text-primary`} />
          <Modal.Title>{t('changeDueDateDay')}</Modal.Title>
          <Modal.Subtitle>{t('chooseANewDueDateDay')}</Modal.Subtitle>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-14">
          <Form
            onSave={handleChange}
            beforeSave={handleBeforeSave}
            customAction={{ name: 'change_invoices_due_date', method: 'put' }}
          >
            <OrdersChangeDueDateDayModalFields />

            <div class="alert alert-dismissible bg-light-warning border-warning border border-dashed d-flex flex-column flex-sm-row p-5 mt-10">
              <i className="fad fa-warning fs-2hx text-warning me-5 mb-5 mb-sm-0"></i>
              <div class="d-flex flex-column pe-0 pe-sm-10">
                <h5 class="fw-semibold">{t('warning')}</h5>

                <span>{t('permitedOnlyOnceIn30Days')}</span>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-10">
              <button className="btn btn-primary">
                {isSubmitting ? t('auth.sending') : t('auth.send')}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  },
  useConfig
)
