import { useResourceRecordsContext } from '@tootz/react-admin'

export const InvoicesListPagination = () => {
  const { Pagination } = useResourceRecordsContext()

  return (
    <div className="py-4">
      <Pagination />
    </div>
  )
}
