import { DateTime } from 'luxon'
import useTranslation from 'next-translate/useTranslation'

import { useResourceRecordsContext } from '@tootz/react-admin'
import { LoadWrapper } from '@tootz/react-admin/components'

import { InvoicesListItem } from '../ListItem'

import { InvoicesListSkeleton } from './Skeleton'

export const InvoicesListItems = ({ pagination, children }) => {
  const { t } = useTranslation()
  const {
    records: invoices,
    isLoading,
    isError,
    isEmpty,
    params,
    refetch,
    config: { overdueStatuses, prePaymentStatuses }
  } = useResourceRecordsContext()

  const perPage = pagination?.perPage || params?.pagination?.perPage

  if (isEmpty)
    return (
      <div>
        <div className="bg-gray-200 rounded-4 d-flex align-items-center justify-content-center p-8 my-4">
          <h5 className="mb-0 text-gray-700">{t('noChargesToShow')}</h5>
        </div>
      </div>
    )

  return (
    <div>
      <LoadWrapper
        isLoading={isLoading}
        isError={isError}
        isEmpty={isEmpty}
        skeleton={<InvoicesListSkeleton items={perPage} />}
      >
        <div className="bg-white d-flex flex-column gap-6 my-4 p-6 rounded-4">
          {invoices.map(invoice => {
            const isPaid =
              invoice.status === 'paid' || invoice.status === 'paid_with_proof'
            const canPay =
              !isPaid &&
              DateTime.fromISO(invoice.due_date).diffNow(['hours']).hours <= 0
            const isOverdue =
              _.includes(overdueStatuses, invoice.status) ||
              (_.includes(prePaymentStatuses, invoice.status) &&
                DateTime.fromISO(invoice.due_date).diffNow(['hours']).hours < 0)
            return (
              <InvoicesListItem.Root key={invoice.id} {...invoice}>
                <InvoicesListItem.Actions>
                  {canPay && (
                    <InvoicesListItem.PayButton
                      uid={invoice?.payment_order?.uid}
                    />
                  )}
                  {isPaid ? (
                    <InvoicesListItem.ShowReceiptButton id={invoice.id} />
                  ) : (
                    <>
                      {invoice?.gateway_service != 'brazilpays' && (
                        <InvoicesListItem.ChangeCreditCardButton
                          orderId={invoice?.payment_order?.id}
                          onHide={refetch}
                        />
                      )}
                      {isOverdue ? (
                        <InvoicesListItem.SendProofOfPaymentButton
                          id={invoice.id}
                          onHide={refetch}
                        />
                      ) : (
                        <InvoicesListItem.ChangeDueDateDayButton
                          orderId={invoice?.payment_order?.id}
                          installment={invoice?.installment}
                          onHide={refetch}
                        />
                      )}
                    </>
                  )}
                </InvoicesListItem.Actions>
              </InvoicesListItem.Root>
            )
          })}
        </div>
      </LoadWrapper>
      {children}
    </div>
  )
}
